import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CategorySelectionType = 'exact' | 'parent' | false;

export interface CategoryTreeState {
  currentlySelectedCategorySlug: string;
  selectedCategory: Record<string, CategorySelectionType | undefined>;
  openedCategories: Record<string, boolean | undefined>;
}

const initialState: CategoryTreeState = {
  currentlySelectedCategorySlug: '1',
  //TODO - think about how to change this (to string?)
  // eslint-disable-next-line @typescript-eslint/naming-convention -- key is a category number
  selectedCategory: { '1': 'exact' },
  // eslint-disable-next-line @typescript-eslint/naming-convention -- key is a category number
  openedCategories: { '1': true },
};

export const categoryTreeSlice = createSlice({
  name: 'categoryTreeReducer',
  initialState,
  reducers: {
    setSelectedCategory: (state, action: PayloadAction<string>) => {
      //setting all previous "selected" categories as not selected
      const previousCategories = state.currentlySelectedCategorySlug.split('-');
      let base = '';
      previousCategories.forEach(pt => {
        if (base !== '') base += '-';
        base += pt;
        state.selectedCategory[base] = false;
      });

      //setting all current categories as selected
      const currentCategories = action.payload.split('-');
      base = '';
      currentCategories.forEach(pt => {
        if (base !== '') base += '-';
        base += pt;
        state.selectedCategory[base] = 'parent';
      });

      //setting clicked category as 'exact' and as last
      state.selectedCategory[action.payload] = 'exact';
      state.currentlySelectedCategorySlug = action.payload;
    },
    setOpenedCategory: (
      state,
      action: PayloadAction<{ categorySlug: string; opened: boolean }>,
    ) => {
      state.openedCategories[action.payload.categorySlug] = action.payload.opened;
    },
    resetState: () => initialState,
  },
});

export const { setSelectedCategory, setOpenedCategory, resetState } = categoryTreeSlice.actions;

export const categoryTreeReducer = categoryTreeSlice.reducer;
