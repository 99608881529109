import { Popover } from 'antd';
import { COLORS, Panel, Text } from '@ydistri/ds';
import React from 'react';
import { styled } from 'styled-components';

const OutlierPanel = styled(Panel)<{ $center?: boolean; $colorVariant?: 'red' | 'orange' }>`
  background-color: ${p =>
    p.$colorVariant === 'red' ? COLORS.TABLE_HIGHLIGHT_RED : COLORS.TABLE_HIGHLIGHT_ORANGE};
  border: 1px solid ${p => (p.$colorVariant === 'red' ? COLORS.DANGER : COLORS.WEB_ORANGE)};
  color: ${p => (p.$colorVariant === 'red' ? COLORS.DANGER : COLORS.WEB_ORANGE)};
  ${p =>
    p.$center ? `justify-content: center;` : `justify-content: flex-end; padding-right: 0.25rem;`}
`;

interface OutlierValueProps {
  value: number | string;
  tooltip?: string;
  center?: boolean;
  colorVariant?: 'red' | 'orange';
}

export const OutlierValue: React.FC<OutlierValueProps> = ({
  value,
  tooltip,
  center,
  colorVariant = 'red',
}) => {
  if (tooltip) {
    return (
      <Popover content={tooltip} trigger="hover" placement="topLeft">
        <OutlierPanel $center={center} $colorVariant={colorVariant}>
          <Text $bold={true}>{value}</Text>
        </OutlierPanel>
      </Popover>
    );
  }

  return (
    <OutlierPanel $center={center}>
      <Text $bold={true}>{value}</Text>
    </OutlierPanel>
  );
};
