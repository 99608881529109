import { AiOutlineSearch } from 'react-icons/ai';
import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Input } from 'antd';
import { InputProps } from 'antd/lib';
import debounce from 'lodash.debounce';
import { computeRemSize } from '../styles/style';

interface SearchBoxProps extends InputProps {
  value?: string;
  /** called when the content of the input changes. */
  onSearch?: (value: string) => void;
  width?: CSSProperties['width'];
  flexGrow?: CSSProperties['flexGrow'];
  id?: string;
}

interface SearchBoxInputProps extends InputProps {
  $flexGrow?: CSSProperties['flexGrow'];
}

const searchBoxIcon = <AiOutlineSearch />;
const SearchBoxInput = styled(Input)<SearchBoxInputProps>`
  height: ${computeRemSize(32)};
  width: ${({ width }) => (width ? `${width};` : '14rem')};
  ${({ $flexGrow }) => ($flexGrow ? `flex-grow: ${$flexGrow};` : '')}
`;

/**
 * Component for search. Displays a search icon. To be used anywhere a user can perform a search.
 * Can take any props from antd Input component but some are overriden (suffix, allowClear for example).
 * @param onSearch called when the content of the input changes.
 * @param value
 * @param flexGrow
 * @param id
 * @param rest
 * @constructor
 */
export const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, value, flexGrow, id, ...rest }) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(() => {
    if (value) {
      return value;
    }
  });

  useEffect(() => {
    setSearchValue(prevValue => {
      if (prevValue !== value) {
        return value;
      }
      return prevValue;
    });
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- debounce requires this
  const fireSearch = useCallback(
    debounce((query: string) => {
      if (onSearch) {
        onSearch(query);
      }
    }, 450),
    [onSearch],
  );

  const enterPressed = useCallback(() => {
    fireSearch(searchValue ?? '');
  }, [fireSearch, searchValue]);

  const searchChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      fireSearch(event.target.value);
    },
    [fireSearch],
  );

  return (
    <SearchBoxInput
      {...rest}
      suffix={searchBoxIcon}
      value={searchValue}
      onChange={searchChangeHandler}
      onPressEnter={enterPressed}
      allowClear
      $flexGrow={flexGrow}
      data-type="SearchBox"
      id={id}
    />
  );
};

SearchBox.displayName = 'SearchBox';
