/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/consistent-type-assertions -- we deliberately break the rules here for constant like properties */
import { generate } from '@ant-design/colors';
import { css } from 'styled-components';
import { CSSProperties } from 'react';

const styleScrollbar = css`
  &::-webkit-scrollbar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`.toString();

/* https://ant.design/docs/spec/colors */
const primaryColors = generate('#f29718');

export const COLORS = {
  PRIMARY: primaryColors[5],
  PRIMARY_LIGHT: primaryColors[0],
  PRIMARY_DARK: primaryColors[8],
  PRIMARY_STEPS: primaryColors,
  WHITE: 'white',
  HOVER_GREY_LIGHT: '#eeeeee',
  GREY: 'grey',
  GREY_BORDER: '#f4f4f4',
  GREY_LIGHT: '#fafafa',
  GREY_MEDIUM: '#d9d9d9',
  GREY_DARK: 'darkgrey',
  BACKGROUND: '#fff',
  BACKGROUND_MENU: '#FFFFFF',
  BORDER: 'rgba(5, 5, 5, 0.06)',
  BLUE: '#3B5998',
  DEADSTOCK_BACKGROUND: 'rgb(252,242,245)' as CSSProperties['color'],
  DEADSTOCK_COLOR: 'rgb(232, 89, 86)' as CSSProperties['color'],
  DEADSTOCK_COLOR_FADED: 'rgba(232, 89, 86, .7)' as CSSProperties['color'],
  SLOWMOVER_BACKGROUND: 'rgb(252,247,243)' as CSSProperties['color'],
  SLOWMOVER_COLOR: 'rgb(244, 168, 61)' as CSSProperties['color'],
  SLOWMOVER_COLOR_FADED: 'rgba(244, 168, 61, .7)' as CSSProperties['color'],
  FASTMOVER_BACKGROUND: 'rgb(242,249,255)' as CSSProperties['color'],
  FASTMOVER_COLOR: 'rgb(150,201,246)' as CSSProperties['color'],
  FASTMOVER_COLOR_FADED: 'rgb(65, 192, 251, .7)' as CSSProperties['color'],
  ALL_SKU_TYPES_COLOR: 'rgb(59, 89, 152)' as CSSProperties['color'],
};

export const STYLE = {
  //restyling ant design components
  PRIMARY_COLOR: COLORS.PRIMARY,
  COLLAPSOR_HEIGHT: `3rem`,
  LEFT_MENU_COLLAPSED_WIDTH: `3.5rem`,
  TOP_MENU_HEIGHT: `3rem`,
  TOP_HEADER_ROW_HEIGHT: `3.5rem`,
  ROW_DIVIDER_COLOR: COLORS.GREY_BORDER,

  //other
  BASE_SHADOW: `0 0 10px 2px rgba(0,0,0,0.3)`,
  SCROLLBAR: styleScrollbar,
  UNIT: 0.5, // means 0.5rem = 8px
  COLUMN_SIZE_IN_REM: (1502 - 12.5 * 16) / 16 / 12, // (BASE WIDTH - (uncollapsed left menu)) / REM SIZE IN PIXELS / COLUMN COUNT
};
