import { CompoundSelectedKey, FilterComponent, YColumnsType } from '@ydistri/ds';
import { get } from 'lodash';

export function makeColumnFilterable<T>(column: YColumnsType<T>): YColumnsType<T> {
  const itemType = column.apiFilterType ?? 'text';

  return {
    ...column,
    filterSearch: true,
    onFilter: (value, record: T): boolean => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
      const filterObject: CompoundSelectedKey = JSON.parse(value.toString()) as CompoundSelectedKey;

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-assignment -- we know the type
      const columnValue = get(record, column.dataIndex as string[]);
      switch (itemType) {
        case 'text': {
          return filterTextValue(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            columnValue as string,
            filterObject.value.toString().toLowerCase(),
            filterObject.comparisonType,
          );
        }
        case 'number':
          return filterNumericValue(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            parseFloat(columnValue as string),
            parseFloat(filterObject.value.toString()),
            filterObject.comparisonType,
          );
        case 'boolean':
          return filterBooleanValue(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
            columnValue as boolean,
            filterObject.comparisonType,
          );

        case 'options':
          return false;
      }
    },
    filterDropdown: props => (
      <FilterComponent
        columnKey={column.key?.toString() ?? ''}
        filter={props}
        displayName={
          typeof column.title === 'string' ? column.title : (column.key?.toString() ?? '')
        }
        itemType={itemType}
      />
    ),
  };
}

/**
 * Function used when the column's apiFilterType is textual.
 * Compares the value and filteredValue using the comparisonType selected using
 * the FilterComponent.
 * @param columnValue Value stored in the data source for the column.
 * @param userEnteredValue Value entered by the user in the filter input.
 * @param comparisonType Comparison type selected by the user using the FilterComponent.
 */
const filterTextValue = (
  columnValue: string,
  userEnteredValue: string,
  comparisonType: string,
): boolean => {
  const filterValueIncluded = columnValue.toLowerCase().includes(userEnteredValue);
  if (comparisonType === 'contains') {
    return filterValueIncluded;
  } else {
    return !filterValueIncluded;
  }
};

/**
 * Function used when the column's apiFilterType is numeric.
 * Compares the value and filteredValue using the comparisonType selected using
 * the FilterComponent.
 * @param columnValue Value stored in the data source for the column.
 * @param userEnteredValue Value entered by the user in the filter input.
 * @param comparisonType Comparison type selected by the user using the FilterComponent.
 */
const filterNumericValue = (
  columnValue: number,
  userEnteredValue: number,
  comparisonType: string,
): boolean => {
  switch (comparisonType) {
    case 'ge':
      return columnValue >= userEnteredValue;
    case 'gt':
      return columnValue > userEnteredValue;
    case 'le':
      return columnValue <= userEnteredValue;
    case 'lt':
      return columnValue < userEnteredValue;
    case 'eq':
      return columnValue === userEnteredValue;
    case 'ne':
      return columnValue !== userEnteredValue;
    default:
      return false;
  }
};

/**
 * Function used when the column's apiFilterType is boolean.
 * Compares the value and filteredValue using the comparisonType selected using
 * the FilterComponent.
 * @param columnValue Value stored in the data source for the column.
 * @param comparisonType Comparison type selected by the user using the FilterComponent.
 */
const filterBooleanValue = (columnValue: boolean, comparisonType: string): boolean => {
  switch (comparisonType) {
    case 'all':
      return true;
    case 'true':
      return columnValue;
    case 'false':
      return !columnValue;
    default:
      return false;
  }
};
