import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EntityIdentificationType,
  RecordImportAction,
  SkuRedistributionResponse,
} from '@ydistri/api-sdk';
import {
  CalculationsTableFilters,
  CalculationTableParams,
  PrivateFilterType,
} from './calculationsTypes';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import {
  InstructionType,
  ProcessingStep,
  RedistributionImportInputData,
} from '../CalculationDetail/Redistribution/parts/import/redistributionImportTypes';
import { ImportedItemRow } from '../CalculationDetail/Redistribution/parts/import/importLib';

type SelectedRowKeysType = Record<number, boolean | undefined>;

export enum RedistributionDisplayMode {
  LIST,
  IMPORT,
}

export type EntityIdentificationDataType = 'Product' | 'Store';

export interface EntityIdentificationData {
  id: string;
  type: EntityIdentificationDataType;
  value: EntityIdentificationType | undefined;
  label?: string;
}

interface EntityImportState {
  identificationData: EntityIdentificationData[];
  instructionSpecification: InstructionType;
  currentStepIndex: ProcessingStep;
  currentStepRunning: boolean;
  inputContent: string;
  verificationTimeStamp: string;
  importItems: ImportedItemRow<RedistributionImportInputData>[];
  importActionFilter: RecordImportAction[];
}

export interface ImportIdentificationChangeData {
  id: string;
  value: EntityIdentificationType;
}

export interface SetImportStepRequest {
  stepIndex: ProcessingStep;
  stepRunning: boolean;
}

export interface CalculationsSliceState {
  expandedSubmergedCalculations: Record<string, number[] | undefined>;
  expandedRowKeys: Record<string, number[] | undefined>;
  selectedRowKeys: Record<string, { arr: number[]; obj: SelectedRowKeysType } | undefined>;
  hasSelectedRowKeys: Record<string, boolean>;
  redistributionSearchQuery?: string;
  selectedSKURedistribution: SkuRedistributionResponse | undefined;
  redistributionFilterBrandId?: number;
  redistributionFilterOutOfStock: boolean;
  redistributionFilterForced: boolean;
  redistributionPairingCount: number;
  redistributionDisplayMode: RedistributionDisplayMode;
  redistributionImport: EntityImportState;
  calculationFilters: CalculationsTableFilters;
  calculationsTableParams: CalculationTableParams;
}

const initialTableFilters: CalculationsTableFilters = {
  showAllPrivateCalculations: PrivateFilterType.MINE,
  showProductionOnly: false,
  showCurrentUserCalculationsOnly: false,
};

const initialState: CalculationsSliceState = {
  expandedSubmergedCalculations: {},
  expandedRowKeys: {},
  selectedRowKeys: {}, //{ arr: [], obj: {} },
  hasSelectedRowKeys: {}, //false,
  redistributionSearchQuery: undefined,
  selectedSKURedistribution: undefined,
  redistributionFilterBrandId: undefined,
  redistributionFilterOutOfStock: false,
  redistributionFilterForced: false,
  redistributionDisplayMode: RedistributionDisplayMode.LIST,
  calculationFilters: { ...initialTableFilters },
  calculationsTableParams: {},
  redistributionPairingCount: NO_VALUE,
  redistributionImport: {
    identificationData: [
      {
        id: 'product',
        type: 'Product',
        value: undefined,
      },
      {
        id: 'store',
        type: 'Store',
        value: undefined,
      },
    ],
    instructionSpecification: InstructionType.QUANTITY,
    currentStepIndex: ProcessingStep.IMPORT,
    currentStepRunning: false,
    inputContent: '',
    importItems: [],
    verificationTimeStamp: '',
    importActionFilter: [],
  },
};

interface TableIdentificator<T> {
  tableId: string;
  data: T;
}

export const calculationsSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    resetExpandedSubmergedCalculations: (state, action: PayloadAction<string>) => {
      state.expandedSubmergedCalculations[action.payload] = [];
    },
    toggleExpandedSubmergedCalculations: (
      state,
      action: PayloadAction<TableIdentificator<number>>,
    ) => {
      const { tableId, data: calculationId } = action.payload;
      if (!state.expandedSubmergedCalculations[tableId]) {
        state.expandedSubmergedCalculations[tableId] = [];
      }
      if (state.expandedSubmergedCalculations[tableId].includes(calculationId)) {
        const removedKeyIndex = state.expandedSubmergedCalculations[tableId].findIndex(
          id => id === calculationId,
        );
        if (removedKeyIndex !== -1) {
          state.expandedSubmergedCalculations[tableId].splice(removedKeyIndex, 1);
        }
      } else {
        state.expandedSubmergedCalculations[tableId].push(calculationId);
      }
    },
    resetExpandedRowKeys: (state, action: PayloadAction<string>) => {
      if (action.payload === 'all') {
        Object.keys(state.expandedRowKeys).forEach(k => {
          state.expandedRowKeys[k] = [];
        });
      } else {
        state.expandedRowKeys[action.payload] = [];
      }
    },
    toggleExpandedRowKey: (state, action: PayloadAction<TableIdentificator<number>>) => {
      const { tableId, data: calculationId } = action.payload;
      if (!state.expandedRowKeys[tableId]) {
        state.expandedRowKeys[tableId] = [];
      }
      if (state.expandedRowKeys[tableId].includes(calculationId)) {
        const removedKeyIndex = state.expandedRowKeys[tableId].findIndex(
          id => id === calculationId,
        );
        if (removedKeyIndex !== -1) {
          state.expandedRowKeys[tableId].splice(removedKeyIndex, 1);
        }
      } else {
        state.expandedRowKeys[tableId].push(calculationId);
      }
    },
    resetSelectedRowKeys: (state, action: PayloadAction<string>) => {
      state.selectedRowKeys[action.payload] = { arr: [], obj: {} };
      state.hasSelectedRowKeys[action.payload] = false;
    },
    setSelectedRowKeys: (state, action: PayloadAction<TableIdentificator<number[]>>) => {
      const { tableId, data: calculationIds } = action.payload;
      state.selectedRowKeys[tableId] = {
        arr: calculationIds,
        obj: calculationIds.reduce<SelectedRowKeysType>((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}),
      };
      state.hasSelectedRowKeys[tableId] = calculationIds.length > 0;
    },
    removeSelectedRowKey: (state, action: PayloadAction<TableIdentificator<number>>) => {
      const { tableId, data: calculationId } = action.payload;

      const tableIdsToRemoveFrom: string[] = [];
      if (tableId === 'all') {
        tableIdsToRemoveFrom.push(...Object.keys(state.selectedRowKeys));
      } else {
        tableIdsToRemoveFrom.push(tableId);
      }

      tableIdsToRemoveFrom.forEach(tid => {
        if (state.selectedRowKeys[tid]?.obj[calculationId]) {
          state.selectedRowKeys[tid].arr = state.selectedRowKeys[tid].arr.filter(
            id => id !== calculationId,
          );
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete the key dynamically
          delete state.selectedRowKeys[tid].obj[calculationId];
        }
        state.hasSelectedRowKeys[tid] = (state.selectedRowKeys[tid]?.arr ?? []).length > 0;
      });
    },
    toggleSelectedRowKey: (state, action: PayloadAction<TableIdentificator<number>>) => {
      const { tableId, data: calculationId } = action.payload;
      if (state.selectedRowKeys[tableId]?.obj[calculationId]) {
        state.selectedRowKeys[tableId].arr = state.selectedRowKeys[tableId].arr.filter(
          id => id !== calculationId,
        );
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete the key dynamically
        delete state.selectedRowKeys[tableId].obj[calculationId];
      } else {
        if (!state.selectedRowKeys[tableId]) {
          state.selectedRowKeys[tableId] = {
            arr: [],
            obj: {},
          };
        }
        state.selectedRowKeys[tableId].arr.push(calculationId);
        state.selectedRowKeys[tableId].obj[calculationId] = true;
      }
      state.hasSelectedRowKeys[tableId] = state.selectedRowKeys[tableId].arr.length > 0;
    },
    searchRedistribution: (state, action: PayloadAction<string | undefined>) => {
      state.redistributionSearchQuery = action.payload;
    },
    selectSKURedistribution: (
      state,
      action: PayloadAction<SkuRedistributionResponse | undefined>,
    ) => {
      state.selectedSKURedistribution = action.payload;
    },
    filterByBrandId: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload === -1) {
        state.redistributionFilterBrandId = undefined;
      } else {
        state.redistributionFilterBrandId = action.payload;
      }
    },
    filterByOutOfStock: (state, action: PayloadAction<boolean>) => {
      state.redistributionFilterOutOfStock = action.payload;
    },
    filterByForced: (state, action: PayloadAction<boolean>) => {
      state.redistributionFilterForced = action.payload;
    },
    resetFilters: state => {
      state.redistributionFilterBrandId = undefined;
      state.redistributionFilterOutOfStock = false;
      state.redistributionFilterForced = false;
    },
    filterCalculations: (state, action: PayloadAction<CalculationsTableFilters>) => {
      state.calculationFilters = action.payload;
    },
    setRedistributionPairingCount: (state, action: PayloadAction<number>) => {
      if (action.payload > 0) {
        state.redistributionPairingCount = action.payload;
      } else {
        state.redistributionPairingCount = NO_VALUE;
      }
    },
    setCalculationsTableParams: (state, action: PayloadAction<CalculationTableParams>) => {
      state.calculationsTableParams = action.payload;
    },
    setRedistributionDisplayMode: (state, action: PayloadAction<RedistributionDisplayMode>) => {
      state.redistributionDisplayMode = action.payload;
    },
    setImportIdentificationData: (state, action: PayloadAction<EntityIdentificationData[]>) => {
      state.redistributionImport.identificationData = action.payload;
    },
    setImportIdentificationValue: (
      state,
      action: PayloadAction<ImportIdentificationChangeData>,
    ) => {
      const { id, value } = action.payload;
      const identificationData = state.redistributionImport.identificationData;
      const identificationIndex = identificationData.findIndex(data => data.id === id);
      if (identificationIndex !== -1) {
        identificationData[identificationIndex].value = value;
      }
    },
    setImportContent: (state, action: PayloadAction<string>) => {
      state.redistributionImport.inputContent = action.payload;
    },
    setImportInstructionSpecification: (state, action: PayloadAction<InstructionType>) => {
      state.redistributionImport.instructionSpecification = action.payload;
    },
    resetImportState: state => {
      state.redistributionImport = initialState.redistributionImport;
    },
    increaseImportStep: (state, action: PayloadAction<boolean>) => {
      state.redistributionImport.currentStepIndex++;
      state.redistributionImport.currentStepRunning = action.payload;
    },
    setImportStep: (state, action: PayloadAction<SetImportStepRequest>) => {
      state.redistributionImport.currentStepIndex = action.payload.stepIndex;
      state.redistributionImport.currentStepRunning = action.payload.stepRunning;
    },
    setImportItems: (
      state,
      action: PayloadAction<ImportedItemRow<RedistributionImportInputData>[]>,
    ) => {
      state.redistributionImport.importItems = action.payload;
    },
    setImportVerificationTimeStamp: (state, action: PayloadAction<string>) => {
      state.redistributionImport.verificationTimeStamp = action.payload;
    },
    toggleImportActionFilter: (state, action: PayloadAction<RecordImportAction>) => {
      //remove action if present and add if not
      if (state.redistributionImport.importActionFilter.includes(action.payload)) {
        state.redistributionImport.importActionFilter =
          state.redistributionImport.importActionFilter.filter(
            filterAction => filterAction !== action.payload,
          );
      } else {
        state.redistributionImport.importActionFilter = [
          ...state.redistributionImport.importActionFilter,
          action.payload,
        ];
      }
    },
    setImportActionFilter: (state, action: PayloadAction<RecordImportAction>) => {
      if (state.redistributionImport.importActionFilter.includes(action.payload)) {
        state.redistributionImport.importActionFilter = [];
      } else {
        state.redistributionImport.importActionFilter = [action.payload];
      }
    },
    resetState: () => initialState,
  },
});

export const {
  resetExpandedSubmergedCalculations,
  toggleExpandedSubmergedCalculations,
  resetExpandedRowKeys,
  toggleExpandedRowKey,
  resetSelectedRowKeys,
  setSelectedRowKeys,
  removeSelectedRowKey,
  toggleSelectedRowKey,
  searchRedistribution,
  selectSKURedistribution,
  filterByBrandId,
  filterByOutOfStock,
  filterCalculations,
  filterByForced,
  resetFilters,
  resetState,
  setRedistributionPairingCount,
  setCalculationsTableParams,
  setRedistributionDisplayMode,
  setImportIdentificationData,
  setImportIdentificationValue,
  setImportContent,
  setImportInstructionSpecification,
  resetImportState,
  increaseImportStep,
  setImportStep,
  setImportItems,
  setImportVerificationTimeStamp,
  toggleImportActionFilter,
  setImportActionFilter,
} = calculationsSlice.actions;

export const calculationsReducer = calculationsSlice.reducer;
