import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserResponse } from '@ydistri/identity-sdk';
import {
  getItem,
  getSelectedTemplateForProject,
  LSKey,
  setItem,
  setSelectedTemplateForProject,
} from './localStore';
import { API, urlProjectCode, urlSelectedTemplateId } from '../swagger/collections';
import * as Sentry from '@sentry/react';

export interface UserState {
  user: UserResponse | undefined;
  selectedProjectCode: string | undefined;
  selectedTemplateId: number | undefined;
  isDeletingTemplate: number | undefined;
}

const initialState: UserState = {
  user: getItem(LSKey.USER),
  selectedProjectCode: urlProjectCode ?? getItem(LSKey.SELECTED_PROJECT_CODE),
  selectedTemplateId:
    urlSelectedTemplateId ??
    getSelectedTemplateForProject(urlProjectCode) ??
    getItem(LSKey.SELECTED_TEMPLATE_ID),
  isDeletingTemplate: undefined,
};

export const userSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserResponse | undefined>) => {
      setItem(LSKey.USER, action.payload);
      state.user = action.payload;
      if (action.payload === undefined) {
        Sentry.setUser(null);
      } else {
        Sentry.setUser({
          id: action.payload.id,
          name: action.payload.fullName,
        });
      }
    },
    setSelectedProjectCode: (state, action: PayloadAction<string | undefined>) => {
      setItem(LSKey.SELECTED_PROJECT_CODE, action.payload);
      setItem(
        LSKey.SELECTED_TEMPLATE_ID,
        getSelectedTemplateForProject(action.payload) ?? undefined,
      );
      state.selectedProjectCode = action.payload;
    },
    setSelectedTemplateId: (state, action: PayloadAction<number | undefined>) => {
      const projectCode = API.instance.defaults.headers.common['Project-Code'];
      if (typeof projectCode === 'string' && action.payload) {
        setSelectedTemplateForProject(projectCode, action.payload);
      }

      API.instance.defaults.headers.common['Template-Id'] = action.payload;
      setItem(LSKey.SELECTED_TEMPLATE_ID, action.payload);
      state.selectedTemplateId = action.payload;
    },
    setIsDeletingTemplate: (state, action: PayloadAction<number | undefined>) => {
      state.isDeletingTemplate = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- no need to do anything but must fulfill the contract
    resetState: () => {},
  },
});

export const {
  setUser,
  setSelectedProjectCode,
  setSelectedTemplateId,
  setIsDeletingTemplate,
  resetState,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
