import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Scope, ScopeConfiguration, ScopeEntity, ScopeModalType } from './scopeLib';
import {
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleScopeMode,
  ConfigurationRuleSetupType,
} from '@ydistri/api-sdk';
import { createEmptyExceptionOverlapData, ExceptionOverlapData } from './scopeLib2';

interface DraggingInfo {
  scopeId?: number;
  startingPriority?: number;
  endingPriority?: number;
  finished?: boolean;
}

interface ScopeState {
  exceptionIds: number[];
  rearrangeMode: boolean;
  draggingInfo?: DraggingInfo;
  modalChanged?: boolean;
  modalScope?: Scope;
  modalType?: ScopeModalType;
  exceptionOverlapData: ExceptionOverlapData;
}

const initialState: ScopeState = {
  exceptionIds: [],
  rearrangeMode: false,
  draggingInfo: undefined,
  modalScope: undefined,
  modalType: undefined,
  modalChanged: false,
  exceptionOverlapData: createEmptyExceptionOverlapData(),
};

interface UpdateScopeConfigurationPayload {
  configuration: Partial<ScopeConfiguration>;
}

interface SetScopeEntityPayload {
  scopeId: number;
  entityType: ConfigurationRuleScopeEntityType;
  entityValue: ScopeEntity;
}

interface UpdateScopeEntitySelectionOptionPayload {
  entityType: ConfigurationRuleScopeEntityType;
  selectionOption: ConfigurationRuleScopeMode;
}

interface UpdateScopeEntitySelectedIdsPayload {
  entityType: ConfigurationRuleScopeEntityType;
  selectedIds: number[];
}

export const scopeSlice = createSlice({
  name: 'scope',
  initialState,
  reducers: {
    setModalScope: (state, action: PayloadAction<Scope | undefined>) => {
      if (action.payload === undefined) {
        const creatingScope = state.modalScope;
        if (creatingScope) {
          creatingScope.creating = false;
        }
      }
      state.modalScope = action.payload;
    },

    setDraggingInfo: (state, action: PayloadAction<Partial<DraggingInfo> | undefined>) => {
      state.draggingInfo =
        action.payload === undefined ? undefined : { ...state.draggingInfo, ...action.payload };
    },

    setModalType: (state, action: PayloadAction<ScopeModalType | undefined>) => {
      state.modalType = action.payload;
    },

    setModalChanged: (state, action: PayloadAction<boolean>) => {
      state.modalChanged = action.payload;
    },

    setExceptionOverlapData: (state, action: PayloadAction<ExceptionOverlapData>) => {
      state.exceptionOverlapData = action.payload;
    },

    setScopeEntity: (state, action: PayloadAction<SetScopeEntityPayload>) => {
      const { entityType, entityValue } = action.payload;
      const existingScope = state.modalScope;
      if (existingScope) existingScope.entities[entityType] = entityValue;
    },

    updateScopeEntitySelectionOption: (
      state,
      action: PayloadAction<UpdateScopeEntitySelectionOptionPayload>,
    ) => {
      const { entityType, selectionOption } = action.payload;
      const existingScope = state.modalScope;

      if (existingScope) {
        // eslint-disable-next-line no-console -- we need to log this
        console.log('Updating...', entityType, selectionOption);
        existingScope.entities[entityType].selectionOption = selectionOption;

        if (selectionOption === ConfigurationRuleScopeMode.All) {
          existingScope.entities[entityType].selectedIds = [];
        }
      }
    },

    updateScopeEntitySelectedIds: (
      state,
      action: PayloadAction<UpdateScopeEntitySelectedIdsPayload>,
    ) => {
      const { entityType, selectedIds } = action.payload;
      const existingScope = state.modalScope;
      if (existingScope) existingScope.entities[entityType].selectedIds = selectedIds;
    },

    updateScopeConfiguration: (state, action: PayloadAction<UpdateScopeConfigurationPayload>) => {
      const { configuration } = action.payload;

      const existingScope = state.modalScope;

      if (existingScope) {
        Object.entries(configuration).forEach(([key, value]) => {
          // eslint-disable-next-line no-console -- we need to log this
          console.log('Replacing... ', key, value);
          // @ts-expect-error "key" is for sure ConfigurationRuleSetupType
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the key is ConfigurationRuleSetupType
          state.modalScope.configuration[key as ConfigurationRuleSetupType] = value;
        });
      }
    },

    toggleRearrangeMode: state => {
      state.rearrangeMode = !state.rearrangeMode;
    },

    resetState: () => initialState,
  },
});

export const {
  setModalScope,
  setModalType,
  setModalChanged,
  setExceptionOverlapData,
  setDraggingInfo,
  updateScopeEntitySelectionOption,
  updateScopeEntitySelectedIds,
  updateScopeConfiguration,
  toggleRearrangeMode,
  resetState,
} = scopeSlice.actions;

export const scopeReducer = scopeSlice.reducer;
