import { ProjectAdministrationSubpage } from '../projectAdministrationLib';
import { EntityListResponse, EntityListType } from '@ydistri/api-sdk';
import { AdministrationItemRow } from '../common/administrationItemsTypes';

export const IMPORT_STEPS = {
  // NO_IMPORT: `step0`,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- we use it like enums here
  ITEM_IMPORT: `step1`,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- we use it like enums here
  ITEM_VALIDATION: `step2`,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- we use it like enums here
  FINALIZATION: `step3`,
} as const;

export type AdministrationItemParserFunction<T extends EntityListType> = (
  entityListType: T,
  content: string,
  parser?: DOMParser,
) => EntityListRow<T>[];

export interface AdministrationItemParser<T extends EntityListType> {
  contentType: string;
  parse: AdministrationItemParserFunction<T>;
}

export const projectAdministrationSubpageByEntityListType: Record<
  EntityListType,
  ProjectAdministrationSubpage
> = {
  [EntityListType.ProductList]: ProjectAdministrationSubpage.PRODUCTLISTS,
  [EntityListType.TargetList]: ProjectAdministrationSubpage.TARGETLISTS,
  [EntityListType.MinLayerList]: ProjectAdministrationSubpage.MINLAYERLISTS,
};

export const pageTitleByEntityListType: Record<EntityListType, string> = {
  [EntityListType.ProductList]: 'Product list administration',
  [EntityListType.TargetList]: 'Target list administration',
  [EntityListType.MinLayerList]: 'Min layer list administration',
};

export const titleByEntityListType: Record<EntityListType, string> = {
  [EntityListType.ProductList]: 'Product list',
  [EntityListType.TargetList]: 'Target list',
  [EntityListType.MinLayerList]: 'Min layer list',
};

export const urlByEntityListType: Record<EntityListType, string> = {
  [EntityListType.ProductList]: 'productlists',
  [EntityListType.TargetList]: 'targetlists',
  [EntityListType.MinLayerList]: 'minlayerlists',
};

export const entityListTypesWithProducts: EntityListType[] = [
  EntityListType.ProductList,
  EntityListType.TargetList,
  EntityListType.MinLayerList,
];
export const entityListTypesWithStores: EntityListType[] = [
  EntityListType.TargetList,
  EntityListType.MinLayerList,
];

/**
 * Create a title suitable for a section in the Entity List Administration page
 * @param entityList
 * @returns string with name, customer id and archived status
 */
export const getSectionTitle = (entityList: EntityListResponse): string => {
  return `${entityList.name ?? '-no title-'} [${entityList.entityListSourceTypeId}]${
    entityList.isArchived ? ' - [archived]' : ''
  }`;
};

export const productDataIndexByEntityListType: Record<EntityListType, string[]> = {
  [EntityListType.ProductList]: ['product'],
  [EntityListType.TargetList]: ['sku', 'product'],
  [EntityListType.MinLayerList]: ['sku', 'product'],
};

export const storeDataIndexByEntityListType: Record<EntityListType, string[]> = {
  [EntityListType.ProductList]: [], //store not present
  [EntityListType.TargetList]: ['sku', 'store'],
  [EntityListType.MinLayerList]: ['sku', 'store'],
};

export interface EntityListInputDataByEntityListType {
  [EntityListType.ProductList]: {
    product: string;
  };
  [EntityListType.TargetList]: {
    store: string;
    product: string;
    count: number;
  };
  [EntityListType.MinLayerList]: {
    store: string;
    product: string;
    count: number;
  };
}

export type EntityListRow<T extends EntityListType> = AdministrationItemRow<
  EntityListInputDataByEntityListType[T]
>;

export const getParseableStringFromPastedData = <T extends EntityListType>(
  data: EntityListRow<T>[],
  separator = '\t',
): string => {
  const translated = data.map(row => {
    const rowParts = [];
    if ('store' in row.inputData) rowParts.push(row.inputData.store);
    if ('product' in row.inputData) rowParts.push(row.inputData.product);
    if ('count' in row.inputData) rowParts.push(row.inputData.count);
    return rowParts.join(separator);
  });
  return translated.join('\n');
};

//-----------------------

const getEntityListItemFromTabularData = <T extends EntityListType>(
  entityListType: T,
  cells: NodeListOf<HTMLTableCellElement>,
): Partial<EntityListInputDataByEntityListType[T]> => {
  const finalResult: Partial<EntityListInputDataByEntityListType[T]> = {};

  cells.forEach((cell, index) => {
    const currentValue = cell.firstChild?.textContent ?? '';

    switch (entityListType) {
      case EntityListType.MinLayerList:
      case EntityListType.TargetList: {
        const result: Partial<
          EntityListInputDataByEntityListType[
            | EntityListType.MinLayerList
            | EntityListType.TargetList]
        > = finalResult;
        switch (index) {
          case 0:
            result.store = currentValue;
            break;
          case 1:
            result.product = currentValue;
            break;
          case 2:
            {
              result.count = 0;
              if (currentValue.length > 0 && isDigitsOnly(currentValue)) {
                const tmpValue = parseInt(currentValue);
                if (!isNaN(tmpValue)) {
                  result.count = tmpValue;
                }
              }
            }

            break;
        }
        return result;
      }
      case EntityListType.ProductList: {
        const result: Partial<EntityListInputDataByEntityListType[EntityListType.ProductList]> =
          finalResult;

        switch (index) {
          case 0:
            result.product = currentValue;
            break;
        }
        return result;
      }
    }
  });

  return finalResult;
};

const getEntityListItemFromStrings = <T extends EntityListType>(
  entityListType: T,
  cells: string[],
): Partial<EntityListInputDataByEntityListType[T]> => {
  const finalResult: Partial<EntityListInputDataByEntityListType[T]> = {};

  cells.forEach((cell, index) => {
    const currentValue = cell;

    switch (entityListType) {
      case EntityListType.MinLayerList:
      case EntityListType.TargetList: {
        const result: Partial<
          EntityListInputDataByEntityListType[
            | EntityListType.MinLayerList
            | EntityListType.TargetList]
        > = finalResult;

        switch (index) {
          case 0:
            result.store = currentValue;
            break;
          case 1:
            result.product = currentValue;
            break;
          case 2:
            {
              result.count = 0;
              if (currentValue.length > 0 && isDigitsOnly(currentValue)) {
                const tmpValue = parseInt(currentValue);
                if (!isNaN(tmpValue)) {
                  result.count = tmpValue;
                }
              }
            }

            break;
        }
        return result;
      }
      case EntityListType.ProductList: {
        const result: Partial<EntityListInputDataByEntityListType[EntityListType.ProductList]> =
          finalResult;
        switch (index) {
          case 0:
            result.product = currentValue;
            break;
        }
        return result;
      }
    }
  });

  return finalResult;
};

const digitsOnlyRegex = new RegExp('^-?\\d+$');
const isDigitsOnly = (content: string) => {
  return digitsOnlyRegex.test(content);
};

function hasStoreProperty(
  item: Partial<EntityListInputDataByEntityListType[EntityListType]>,
): item is { store: string } {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- just a quick check, no need to define a new type
  return (item as { store?: string }).store !== undefined;
}

function hasProductProperty(
  item: Partial<EntityListInputDataByEntityListType[EntityListType]>,
): item is { product: string } {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- just a quick check, no need to define a new type
  return (item as { product?: string }).product !== undefined;
}

function hasCountProperty(
  item: Partial<EntityListInputDataByEntityListType[EntityListType]>,
): item is { count: number } {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- just a quick check, no need to define a new type
  return (item as { count?: number }).count !== undefined;
}

const checkStore = <T extends EntityListType>(
  item: Partial<EntityListInputDataByEntityListType[T]>,
  errors: string[],
) => {
  if (hasStoreProperty(item)) {
    if (item.store.length === 0) {
      errors.push('Store cannot be empty');
    }
  } else {
    errors.push('Store cannot be empty');
  }
};

const checkProduct = <T extends EntityListType>(
  item: Partial<EntityListInputDataByEntityListType[T]>,
  errors: string[],
) => {
  if (hasProductProperty(item)) {
    if (item.product.length === 0) {
      errors.push('Product cannot be empty');
    }
  } else {
    errors.push('Product cannot be empty');
  }
};

const checkCount = <T extends EntityListType>(
  item: Partial<EntityListInputDataByEntityListType[T]>,
  errors: string[],
) => {
  if (hasCountProperty(item)) {
    if (item.count === 0) {
      errors.push('Quantity cannot be empty');
    }

    if (item.count < 0) {
      errors.push('Quantity cannot be negative');
    }
  } else {
    errors.push('Quantity cannot be empty');
  }
};

const validateEntityListItem = <T extends EntityListType>(
  entityListType: T,
  item: Partial<EntityListInputDataByEntityListType[T]>,
): string[] => {
  const errors: string[] = [];

  switch (entityListType) {
    case EntityListType.MinLayerList:
      checkStore(item, errors);
      checkProduct(item, errors);
      checkCount(item, errors);
      break;
    case EntityListType.TargetList:
      checkStore(item, errors);
      checkProduct(item, errors);
      checkCount(item, errors);
      break;
    case EntityListType.ProductList:
      checkProduct(item, errors);
      break;
  }

  return errors;
};

/**
 * Parse html table in content string. The table should have three columns with warehouse identification (string), product
 * identification (string) and quantity (number)
 * @param entityListType
 * @param content  HTML containing a table to be parsed
 * @param parser DOMParser to be used for parsing. If not present a default DOMParser will be used.
 */
export const parseTabularDataFromHtml = <T extends EntityListType>(
  entityListType: T,
  content: string,
  parser?: DOMParser,
): EntityListRow<T>[] => {
  if (!parser) {
    parser = new DOMParser();
  }

  const htmlDoc = parser.parseFromString(content, 'text/html');
  const rows = htmlDoc.querySelectorAll('table tr');

  const result: EntityListRow<T>[] = [];

  rows.forEach((row, index) => {
    const cells = row.querySelectorAll('td');

    const rowData: Partial<EntityListInputDataByEntityListType[typeof entityListType]> =
      getEntityListItemFromTabularData(entityListType, cells);
    const errors = validateEntityListItem(entityListType, rowData);

    const resultRow: EntityListRow<T> = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

/**
 * Parse the content string and return structured data.
 * The string should contain rows of three columns separated by either a space, comma, pipe, semicolon or tab.
 * @param entityListType
 * @param content  plain text containing data to be parsed
 */
export const parseTabularDataFromPlainText = <T extends EntityListType>(
  entityListType: T,
  content: string,
): EntityListRow<T>[] => {
  const result: EntityListRow<T>[] = [];

  const rows = content.split(/\r\n|\n/).filter(tmpRow => tmpRow.length > 0);
  rows.forEach((row, index) => {
    const cells = row.split(/[(,|;)]+|\t/);
    const rowData = getEntityListItemFromStrings(entityListType, cells);
    const errors = validateEntityListItem(entityListType, rowData);

    const resultRow: EntityListRow<T> = {
      rowNumber: index + 1,
      inputData: rowData,
    };

    if (errors.length > 0) {
      resultRow.isError = true;
      resultRow.errors = errors;
    }

    result.push(resultRow);
  });

  return result;
};

export const selectParser = <T extends EntityListType>(
  data: DataTransfer,
  parsers: AdministrationItemParser<T>[],
): AdministrationItemParser<T> | null => {
  const dataTypes = data.types;

  const suitableParser = parsers.find(parser => dataTypes.includes(parser.contentType));
  if (suitableParser) {
    return suitableParser;
  } else {
    return null;
  }
};

/**
 * Parsers available for different content types.
 * text/html can be copied from Excel or a web page
 * text/plain can be typed directly or copied from a third party system's export
 */
const getEntityListParsers = <T extends EntityListType>(): AdministrationItemParser<T>[] => [
  {
    contentType: 'text/html',
    parse: parseTabularDataFromHtml<T>,
  },
  {
    contentType: 'text/plain',
    parse: parseTabularDataFromPlainText<T>,
  },
];

export const selectEntityListParser = <T extends EntityListType>(
  data: DataTransfer,
): AdministrationItemParser<T> | null => {
  return selectParser(data, getEntityListParsers<T>());
};

export const getParserForContentType = <T extends EntityListType>(
  contentType: string,
): AdministrationItemParser<T> | undefined => {
  return getEntityListParsers<T>().find(parser => parser.contentType === contentType);
};

export const getRowKey = <T extends EntityListType>(row: EntityListRow<T>): string => {
  const keyParts: string[] = [];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- store is string
  if ('store' in row.inputData) keyParts.push(`s${row.inputData.store as string}`);
  if ('product' in row.inputData) keyParts.push(`p${row.inputData.product}`);
  return keyParts.join('-');
};

/**
 * Processes the rows and marks the duplicates according to the compare function.
 * Changes data in place.
 * @param rows data to be processed
 */
export const markDuplicates = <T extends EntityListType>(rows: EntityListRow<T>[]): void => {
  const keyMap: Record<string, number | undefined> = {};

  rows.forEach((row, index) => {
    const rowKey = getRowKey(row);
    if (keyMap[rowKey]) {
      row.isError = true;
      row.isDuplicate = true;

      const duplicateMessage = `Is duplicate to row ${keyMap[rowKey]} from original data`;
      if (!row.errors) {
        row.errors = [duplicateMessage];
      } else {
        row.errors.push(duplicateMessage);
      }
    } else {
      keyMap[rowKey] = index + 1;
    }
  });
};
