import { IdentificationType, ProductListResponse, ProductResponse } from '@ydistri/api-sdk';
import { ActiveScreenType, markDuplicates } from '../common/administrationItemsLib';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductListInputData, ProductListRow, ProductListValidatedItem } from './productListTypes';
import {
  AdministrationItemContentCompared,
  AdministrationItemListHandlingMethod,
} from '../common/administrationItemsTypes';

export interface ValidationFinishedPayload {
  validation: AdministrationItemContentCompared<ProductListInputData, ProductResponse> | undefined;
  errors?: string;
}

export interface ProductListAdministrationState {
  selectedProductList?: ProductListResponse;
  activeScreenType: ActiveScreenType;
  showArchivedItems: boolean;
  sortField?: string;
  productIdentificationType: IdentificationType;
  parsedProductListItems: ProductListRow[];
  handlingMethod?: AdministrationItemListHandlingMethod;
  productItemsContent: string;
  productItemsErrors: string;
  validation: AdministrationItemContentCompared<ProductListInputData, ProductResponse> | undefined;
  isLoading: boolean;
  refreshProductListContentFlip: boolean;
}

const initialState: ProductListAdministrationState = {
  selectedProductList: undefined,
  validation: undefined,
  activeScreenType: ActiveScreenType.NO_SELECTION,
  showArchivedItems: false,
  sortField: 'LastUpdatedAt',
  productIdentificationType: IdentificationType.CustomerId,
  parsedProductListItems: [],
  productItemsContent: '',
  productItemsErrors: '',
  isLoading: false,
  refreshProductListContentFlip: false,
};

const appendValidationHelper = (
  data: AdministrationItemContentCompared<ProductListInputData, ProductResponse> | undefined,
  currentValidation:
    | AdministrationItemContentCompared<ProductListInputData, ProductResponse>
    | undefined,
) => {
  if (currentValidation) {
    return {
      ...currentValidation,
      items: currentValidation.items
        .filter(validatedItem => {
          const foundIndex = data?.items.findIndex(item => {
            return validatedItem.inputData.productId === item.inputData.productId;
          });
          return foundIndex === -1;
        })
        .concat(data?.items ?? []),
    };
  } else {
    return data;
  }
};

const findValidationError = (
  validationItems: ProductListValidatedItem[],
  productIdentityId: IdentificationType,
  productId?: string,
): ProductListValidatedItem | null => {
  const found = validationItems.find(item => {
    if (item.isError) {
      return productId === item.inputData.productId;
    } else {
      return false;
    }
  });

  return found ?? null;
};

export const productListAdministrationSlice = createSlice({
  name: 'productListsAdministration',
  initialState,
  reducers: {
    setSelectedProductList: (state, action: PayloadAction<ProductListResponse | undefined>) => {
      const previousSelectedProductListId = state.selectedProductList?.productListId;
      state.selectedProductList = action.payload;

      state.validation = undefined;
      state.parsedProductListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';

      if (action.payload) {
        if (action.payload.productListId !== previousSelectedProductListId) {
          state.activeScreenType = ActiveScreenType.DETAIL;
        }
      } else {
        state.activeScreenType = ActiveScreenType.NO_SELECTION;
      }
    },
    setActiveScreenType: (state, action: PayloadAction<ActiveScreenType>) => {
      state.activeScreenType = action.payload;
    },
    setShowArchivedItems: (state, action: PayloadAction<boolean>) => {
      state.showArchivedItems = action.payload;
    },
    setSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
    },
    setProductIdentificationType: (state, action: PayloadAction<IdentificationType>) => {
      state.productIdentificationType = action.payload;
    },
    resetProductListItems: state => {
      state.parsedProductListItems = [];
    },
    setHandlingMethod: (
      state,
      action: PayloadAction<AdministrationItemListHandlingMethod | undefined>,
    ) => {
      state.handlingMethod = action.payload;
    },
    setProductItemsContent: (state, action: PayloadAction<string>) => {
      state.productItemsContent = action.payload;
    },
    setProductItemsError: (state, action: PayloadAction<string>) => {
      state.productItemsErrors = action.payload;
    },
    setProductListItems: (state, action: PayloadAction<ProductListRow[]>) => {
      const newItems = Array.from(action.payload);
      markDuplicates(
        newItems,
        (left, right) => left.inputData.productId === right.inputData.productId,
      );

      state.parsedProductListItems = newItems;
    },
    appendProductListItems: (state, action: PayloadAction<ProductListRow[]>) => {
      // When appending we assume the user is fixing errors from previous step (either pasting from Excel or manually).
      // So we clear the current parsed items from errors

      //filter out errors detected in UI
      const orgWithoutErrors = Array.from(state.parsedProductListItems).filter(
        item => !item.isError,
      );

      //filter out errors detected on backend (unknown product)
      let orgWithoutValidationErrors = Array.from(orgWithoutErrors);
      if (state.validation) {
        const productIdentification = state.validation.result?.productIdentificationTypeId;

        orgWithoutValidationErrors = orgWithoutErrors.filter(item => {
          const validationError = findValidationError(
            state.validation?.items ?? [],
            productIdentification ?? IdentificationType.CustomerId,
            item.inputData.productId,
          );
          return validationError === null;
        });
      }

      //use non error items and append items edited by hand
      const newItems = orgWithoutValidationErrors.concat(action.payload).map((item, index) => {
        item.rowNumber = index + 1;
        return item;
      });

      markDuplicates(
        newItems,
        (left, right) => left.inputData.productId === right.inputData.productId,
      );
      state.parsedProductListItems = newItems;
    },
    clearValidation: state => {
      state.validation = undefined;
      state.parsedProductListItems = [];
      state.productItemsContent = '';
      state.productItemsErrors = '';
    },
    setValidation: (
      state,
      action: PayloadAction<
        AdministrationItemContentCompared<ProductListInputData, ProductResponse> | undefined
      >,
    ) => {
      state.validation = action.payload;
    },
    appendValidation: (
      state,
      action: PayloadAction<
        AdministrationItemContentCompared<ProductListInputData, ProductResponse> | undefined
      >,
    ) => {
      state.validation = appendValidationHelper(action.payload, state.validation);
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    refreshProductListContent: state => {
      state.refreshProductListContentFlip = !state.refreshProductListContentFlip;
    },
    validationFinished: (state, action: PayloadAction<ValidationFinishedPayload>) => {
      state.validation = appendValidationHelper(action.payload.validation, state.validation);
      state.activeScreenType = ActiveScreenType.VALIDATION;
      state.productItemsErrors = action.payload.errors ?? '';
      state.productItemsContent = '';
    },
    resetState: () => initialState,
  },
});

export const {
  setSelectedProductList,
  setActiveScreenType,
  setShowArchivedItems,
  setSortField,
  setProductIdentificationType,
  resetProductListItems,
  setHandlingMethod,
  appendProductListItems,
  setProductItemsContent,
  setProductItemsError,
  clearValidation,
  setIsLoading,
  setValidation,
  refreshProductListContent,
  resetState,
  appendValidation,
  setProductListItems,
  validationFinished,
} = productListAdministrationSlice.actions;

export const productListAdministrationReducer = productListAdministrationSlice.reducer;
