import {
  ConfigurationFieldType,
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleSetupType,
  ConfigurationSectionType,
  RegionFlowType,
  SkuType,
} from '@ydistri/api-sdk';
import { ValidatorSection } from '../../screens/Configuration/Validator/validatorLib';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableSkuTypes = Object.keys(SkuType) as SkuType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableConfigurationRuleScopeEntityType = Object.keys(
  ConfigurationRuleScopeEntityType,
) as ConfigurationRuleScopeEntityType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableConfigurationRuleSetupType = Object.keys(
  ConfigurationRuleSetupType,
) as ConfigurationRuleSetupType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableConfigurationSectionTypes = Object.keys(
  ConfigurationSectionType,
) as ConfigurationSectionType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableConfigurationFieldTypes = Object.keys(
  ConfigurationFieldType,
) as ConfigurationFieldType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableRegionFlowTypes = Object.keys(RegionFlowType) as RegionFlowType[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to type this
export const iterableValidatorSections = Object.keys(ValidatorSection) as ValidatorSection[];
