import {
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleScopeMode,
  ConfigurationRuleSetupType,
  DepartmentResponse,
  RegionResponse,
  StoreResponse,
  StoreTypeResponse,
} from '@ydistri/api-sdk';
import { Scope, ScopeConfiguration } from './scopeLib';
import { ListType } from '../../../apis/apiLists';
import { iterableConfigurationRuleSetupType } from '../../../helpers/types/iterableEnums';

type FilteredEntityIds = Record<ConfigurationRuleScopeEntityType, number[]>;
export type IdMap = Record<number, true | undefined>;

interface ExceptionConfiguration<T extends ConfigurationRuleSetupType> {
  value: ScopeConfiguration[T];
  exception: number;
}

type ExceptionConfigurations<T extends ConfigurationRuleSetupType> = Record<
  T,
  { array: ExceptionConfiguration<T>[]; object: IdMap }
>;

export interface StoreDepartmentCombination {
  storeId: number;
  departmentId: number;
  storeTypeId: number;
  exceptions: ExceptionConfigurations<ConfigurationRuleSetupType>;
  final: ScopeConfiguration;
  included: boolean;
}

const createEmptyArrayObject = <T = number>(): ArrayObject<T> => ({
  object: {},
  array: [],
});

const createEmptyOverlapConfigurations = (): OverlapConfigurations => ({
  [ConfigurationRuleSetupType.UseAsSource]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.UseAsTarget]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.IsEnabled]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.IsClosing]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.IsSuperTarget]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.MaxNumberOfSourceStores]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.MaxNumberOfTargetStores]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions]: createEmptyArrayObject(),
  [ConfigurationRuleSetupType.MinimumRouteValue]: createEmptyArrayObject(),
});

const createEmptyExceptions = (): ExceptionConfigurations<ConfigurationRuleSetupType> => ({
  [ConfigurationRuleSetupType.UseAsSource]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.UseAsSource>>(),
  [ConfigurationRuleSetupType.UseAsTarget]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.UseAsTarget>>(),
  [ConfigurationRuleSetupType.IsEnabled]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.IsEnabled>>(),
  [ConfigurationRuleSetupType.IsClosing]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.IsClosing>>(),
  [ConfigurationRuleSetupType.IsSuperTarget]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.IsSuperTarget>>(),
  [ConfigurationRuleSetupType.MaxNumberOfSourceStores]:
    createEmptyArrayObject<
      ExceptionConfiguration<ConfigurationRuleSetupType.MaxNumberOfSourceStores>
    >(),
  [ConfigurationRuleSetupType.MaxNumberOfTargetStores]:
    createEmptyArrayObject<
      ExceptionConfiguration<ConfigurationRuleSetupType.MaxNumberOfTargetStores>
    >(),
  [ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions]:
    createEmptyArrayObject<
      ExceptionConfiguration<ConfigurationRuleSetupType.MaxNumberOfSourcePickingPositions>
    >(),
  [ConfigurationRuleSetupType.MinimumRouteValue]:
    createEmptyArrayObject<ExceptionConfiguration<ConfigurationRuleSetupType.MinimumRouteValue>>(),
});

interface ArrayObject<T = number> {
  object: IdMap;
  array: T[];
}

type OverlapConfigurations = Record<ConfigurationRuleSetupType, ArrayObject>;

type Overlap = Record<
  number,
  | {
      exceptions: ArrayObject;
      configurations: OverlapConfigurations;
    }
  | undefined
>;

export enum ScopeErrorType {
  IS_CLOSING_OVERLAPS_SUPERTARGET = 'isClosingOverlapsSuperTarget',
  IS_SUPERTARGET_OVERLAPS_CLOSING = 'isSuperTargetOverlapsClosing',
}

export interface ExceptionOverlapData {
  storeTypeStores: Record<number, number[] | undefined>;
  scopeList: ListType<Scope>;
  overlap: Overlap;
  regionIds: number[];
  storeIds: number[];
  storeTypeIds: number[];
  departmentIds: number[];
  matrix: Record<string, StoreDepartmentCombination | undefined>;
  scopesWithErrors: Record<number, Record<ScopeErrorType, number[] | undefined> | undefined>;
}

export const createEmptyExceptionOverlapData = (): ExceptionOverlapData => ({
  storeTypeStores: {},
  scopeList: {
    array: [],
    object: {},
  },
  overlap: {},
  regionIds: [],
  storeIds: [],
  storeTypeIds: [],
  departmentIds: [],
  matrix: {},
  scopesWithErrors: {},
});

export interface TransformExceptionOverlapDataArgs {
  scopes: Scope[];
  regionList: ListType<RegionResponse>;
  storeList: ListType<StoreResponse>;
  storeTypeList: ListType<StoreTypeResponse>;
  departmentList: ListType<DepartmentResponse>;
  useClosing: boolean;
  useSuperTarget: boolean;
}

export const transformExceptionOverlapData = (
  args: TransformExceptionOverlapDataArgs,
): ExceptionOverlapData => {
  const { scopes, regionList, departmentList, useClosing, useSuperTarget } = args;
  let { storeList, storeTypeList } = args;

  if (scopes.length === 0) throw new Error('0 rules received!');
  const rules = scopes.toSorted((r1, r2) => r1.priority - r2.priority);

  const mainRule = rules.shift();
  if (!mainRule) throw new Error('main rule not found!');
  if (mainRule.priority !== 0) throw new Error('main rule has NOT priority 0!');
  const exceptions = rules;

  // filter provided storeList and storeTypeList by enabled regions
  const newStoreList: ListType<StoreResponse> = { array: [], object: {} };
  const newStoreTypeList: ListType<StoreTypeResponse> = { array: [], object: {} };
  storeList.array.forEach(s => {
    const regionId = s.region?.id ?? 0;
    if (regionList.object[regionId]) {
      newStoreList.array.push(s);
      newStoreList.object[s.id] = s;
      const storeTypeId = s.storeType?.id ?? 0;
      const storeType = storeTypeList.object[storeTypeId];
      if (!newStoreTypeList.object[storeTypeId]) {
        if (storeType) {
          newStoreTypeList.array.push(storeType);
          newStoreTypeList.object[storeTypeId] = storeType;
        }
      }
    }
  });

  // replace storeList and storeTypeList with filtered lists => now, we have only stores in enabled regions and store types with at least one store in enabled regions
  storeList = newStoreList;
  storeTypeList = newStoreTypeList;

  // prepare response object
  const rsp: ExceptionOverlapData = {
    storeTypeStores: {},
    scopeList: {
      array: [],
      object: {},
    },
    overlap: {},
    regionIds: regionList.array.map(s => s.id),
    storeIds: storeList.array.map(s => s.id),
    storeTypeIds: storeTypeList.array.map(st => st.id),
    departmentIds: departmentList.array.map(d => d.id),
    matrix: {},
    scopesWithErrors: {},
  };

  //============= parse main rule =============
  const mainRuleScope = mainRule;

  const {
    [ConfigurationRuleScopeEntityType.Regions]: filteredRegions,
    [ConfigurationRuleScopeEntityType.Stores]: filteredStores,
    [ConfigurationRuleScopeEntityType.StoreTypes]: filteredStoreTypes,
    [ConfigurationRuleScopeEntityType.Departments]: filteredDepartments,
  } = filterRuleScope({
    scope: mainRuleScope,
    regionIds: rsp.regionIds,
    storeIds: rsp.storeIds,
    storeListObject: storeList.object,
    storeTypeIds: rsp.storeTypeIds,
    departmentIds: rsp.departmentIds,
  });

  // make matrix of filtered stores X filtered departments
  filteredStores.forEach(s => {
    const storeTypeId = storeList.object[s]?.storeType?.id ?? 0;
    const storeTypeStores = rsp.storeTypeStores[storeTypeId];
    if (!storeTypeStores) {
      rsp.storeTypeStores[storeTypeId] = [s];
    } else {
      storeTypeStores.push(s);
    }

    filteredDepartments.forEach(d => {
      const key = matrixKey(s, d);
      rsp.matrix[key] = {
        storeId: s,
        storeTypeId: storeTypeId,
        departmentId: d,
        exceptions: createEmptyExceptions(),
        final: { ...mainRuleScope.configuration },
        included: true,
      };
    });
  });

  exceptions.forEach(e => {
    rsp.scopeList.object[e.id] = e;
    rsp.scopeList.array.push(e);
    const exceptionRule = e;
    const {
      // [ConfigurationRuleScopeEntityType.Regions]: exceptionStores,
      [ConfigurationRuleScopeEntityType.Stores]: exceptionStores,
      // [ConfigurationRuleScopeEntityType.StoreTypes]: _filteredStoreTypes,
      [ConfigurationRuleScopeEntityType.Departments]: exceptionDepartments,
    } = filterRuleScope({
      scope: exceptionRule,
      regionIds: filteredRegions,
      storeIds: filteredStores,
      storeListObject: storeList.object,
      storeTypeIds: filteredStoreTypes,
      departmentIds: filteredDepartments,
    });

    exceptionStores.forEach(storeId => {
      exceptionDepartments.forEach(departmentId => {
        const key = matrixKey(storeId, departmentId);
        const combinationData = rsp.matrix[key];
        if (!combinationData) throw new Error('combinationData should be available by now!');

        iterableConfigurationRuleSetupType.forEach(c => {
          let config = exceptionRule.configuration[c];
          if (
            (c === ConfigurationRuleSetupType.IsClosing && !useClosing) ||
            (c === ConfigurationRuleSetupType.IsSuperTarget && !useSuperTarget)
          ) {
            config = null;
          }

          // if (config !== null) {
          // @ts-expect-error we are sure that types are right here

          if (config !== null) combinationData.final[c] = config;

          if (combinationData.exceptions[c].array.length > 0) {
            const pastExceptions = combinationData.exceptions[c];
            pastExceptions.array.forEach(pe => {
              if (!rsp.overlap[pe.exception]) {
                rsp.overlap[pe.exception] = {
                  exceptions: {
                    object: {},
                    array: [],
                  },
                  configurations: createEmptyOverlapConfigurations(),
                };
              }

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- somehow we are sure that it is not null
              const overlapForPastException = rsp.overlap[pe.exception]!;

              if (!overlapForPastException.exceptions.object[e.id]) {
                overlapForPastException.exceptions.object[e.id] = true;
                overlapForPastException.exceptions.array.push(e.id);
              }

              if (config !== null) {
                if (!overlapForPastException.configurations[c].object[e.id]) {
                  overlapForPastException.configurations[c].object[e.id] = true;
                  overlapForPastException.configurations[c].array.push(e.id);
                }
              }
            });
          }

          if (!combinationData.exceptions[c].object[e.id] && config !== null) {
            combinationData.exceptions[c].array.push({
              value: config,
              exception: e.id,
            });
            combinationData.exceptions[c].object[e.id] = true;
          }
          // }
        });
      });
    });
  });

  if (useClosing && useSuperTarget) {
    rsp.scopeList.array.forEach(e => {
      const overlapExceptions = rsp.overlap[e.id]?.exceptions.array ?? [];
      if (overlapExceptions.length > 0) {
        const thisExceptionErrors = rsp.scopesWithErrors[e.id];
        if (!thisExceptionErrors)
          rsp.scopesWithErrors[e.id] = {
            [ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING]: [],
            [ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET]: [],
          };
        overlapExceptions.forEach(oe => {
          if (
            e.configuration[ConfigurationRuleSetupType.IsClosing] &&
            rsp.scopeList.object[oe]?.configuration.IsSuperTarget
          ) {
            rsp.scopesWithErrors[e.id]?.[ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING]?.push(oe);
          } else if (
            e.configuration[ConfigurationRuleSetupType.IsSuperTarget] &&
            rsp.scopeList.object[oe]?.configuration.IsClosing
          ) {
            rsp.scopesWithErrors[e.id]?.[ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET]?.push(oe);
          }
        });
      }
    });
  }

  return rsp;
};

/**
 * Filters list entity IDs down to scope definition.
 *
 * @param {Scope} scope - The scope object containing entities and their selected IDs.
 * @param {ConfigurationRuleScopeEntityType} entity - The entity type to filter on.
 * @param {number[]} entityIds - The list of numbers to filter.
 * @returns {number[]} - The filtered list of numbers.
 */
const filterEntity = (
  scope: Scope,
  entity: ConfigurationRuleScopeEntityType,
  entityIds: number[],
): number[] => {
  const selectedIdsObject = numberArrayToObject(scope.entities[entity].selectedIds);

  if (scope.entities[entity].selectionOption === ConfigurationRuleScopeMode.AllExcept) {
    return entityIds.filter(l => !selectedIdsObject[l]);
  } else if (scope.entities[entity].selectionOption === ConfigurationRuleScopeMode.Specified) {
    return entityIds.filter(l => selectedIdsObject[l]);
  }
  return entityIds;
};

interface FilterRuleScopeArgs {
  scope: Scope;
  regionIds: number[];
  storeIds: number[];
  storeListObject: ListType<StoreResponse>['object'];
  storeTypeIds: number[];
  departmentIds: number[];
}

/**
 * Returns resulting IDs of all entity types for whole scope
 * Basically calls filterEntity for all entity types, but also filters stores based on store types
 *
 * @returns {FilteredEntityIds} - The filtered entity IDs.
 * @param args
 */
const filterRuleScope = (args: FilterRuleScopeArgs): FilteredEntityIds => {
  const { scope, regionIds, storeIds, storeListObject, storeTypeIds, departmentIds } = args;

  // filter regions based on scope
  const filteredRegionList = filterEntity(
    scope,
    ConfigurationRuleScopeEntityType.Regions,
    regionIds,
  );
  const regionTypeObject = numberArrayToObject(filteredRegionList);

  // filter store types based on scope
  const filteredStoreTypeList = filterEntity(
    scope,
    ConfigurationRuleScopeEntityType.StoreTypes,
    storeTypeIds,
  );
  const storeTypeObject = numberArrayToObject(filteredStoreTypeList);

  //filter stores based on scope AND filtered store types
  const filteredStoreList = filterEntity(
    scope,
    ConfigurationRuleScopeEntityType.Stores,
    storeIds,
  ).filter(sid => {
    const storeTypeId = storeListObject[sid]?.storeType?.id;
    const regionId = storeListObject[sid]?.region?.id;
    return storeTypeId && storeTypeObject[storeTypeId] && regionId && regionTypeObject[regionId];
  });

  //filter departments based on scope
  const filteredDepartmentList = filterEntity(
    scope,
    ConfigurationRuleScopeEntityType.Departments,
    departmentIds,
  );

  return {
    [ConfigurationRuleScopeEntityType.Regions]: filteredRegionList,
    [ConfigurationRuleScopeEntityType.StoreTypes]: filteredStoreTypeList,
    [ConfigurationRuleScopeEntityType.Stores]: filteredStoreList,
    [ConfigurationRuleScopeEntityType.Departments]: filteredDepartmentList,
  };
};

export const matrixKey = (storeId: number, departmentId: number): string =>
  `${storeId}-${departmentId}`;

export const getIdMapFromArray = <T>(arr: T[], condition: (element: T) => number): IdMap => {
  return arr.reduce<IdMap>((p, c) => {
    const successId = condition(c);
    if (successId > 0) p[successId] = true;
    return p;
  }, {});
};

export const numberArrayToObject = (list: number[]): IdMap => {
  return list.reduce<IdMap>((p, c) => {
    p[c] = true;
    return p;
  }, {});
};
