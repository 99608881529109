import { ProjectResponse, UserResponse } from '@ydistri/identity-sdk';

export type SelectedTemplatesForProject = Record<string, number | undefined>;
export type ProjectInfo = Record<string, ProjectResponse | undefined>;

export enum LSKey {
  TOKEN = '1',
  TOKEN_EXPIRATION = '4',
  MENU_COLLAPSED = '2',
  SELECTED_PROJECT_CODE = '3',
  USER = '5',
  SELECTED_TEMPLATE_ID = '6',
  SELECTED_TEMPLATES_FOR_PROJECT = '7',
  PROJECTS_BY_GUID = '8',
  PROJECTS_BY_SHORT_NAME = '9',
}

export interface LSValues {
  [LSKey.TOKEN]?: string;
  [LSKey.TOKEN_EXPIRATION]?: string;
  [LSKey.MENU_COLLAPSED]?: boolean;
  [LSKey.SELECTED_PROJECT_CODE]?: string;
  [LSKey.USER]?: UserResponse;
  [LSKey.SELECTED_TEMPLATE_ID]?: number;
  [LSKey.SELECTED_TEMPLATES_FOR_PROJECT]?: SelectedTemplatesForProject;
  [LSKey.PROJECTS_BY_GUID]?: ProjectInfo;
  [LSKey.PROJECTS_BY_SHORT_NAME]?: ProjectInfo;
}

export function setItem<T extends LSKey>(key: T, value: LSValues[T]): void {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
}

export function removeItem(key: LSKey): void {
  localStorage.removeItem(key);
}

export function getItem<T extends LSKey>(key: T): LSValues[T] | undefined {
  const savedItem = localStorage.getItem(key);
  if (savedItem && savedItem !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's the right type
    return JSON.parse(savedItem) as LSValues[T];
  }
  return undefined;
}

export function setProjectInfo(projects: ProjectResponse[]): void {
  const projectInfoByCode: ProjectInfo = {};
  const projectInfoByShortName: ProjectInfo = {};
  projects.forEach(p => {
    projectInfoByCode[p.id] = p;
    projectInfoByShortName[p.shortName] = p;
  });
  setItem(LSKey.PROJECTS_BY_GUID, projectInfoByCode);
  setItem(LSKey.PROJECTS_BY_SHORT_NAME, projectInfoByShortName);
}

export function setSelectedTemplateForProject(code: string, templateId: number | undefined): void {
  const t = getItem(LSKey.SELECTED_TEMPLATES_FOR_PROJECT);
  if (t) {
    t[code] = templateId;
    setItem(LSKey.SELECTED_TEMPLATES_FOR_PROJECT, t);
  } else {
    setItem(LSKey.SELECTED_TEMPLATES_FOR_PROJECT, {
      [code]: templateId,
    });
  }
}

export function getSelectedTemplateForProject(code: string | undefined): number | undefined {
  if (!code) return undefined;
  return getItem(LSKey.SELECTED_TEMPLATES_FOR_PROJECT)?.[code];
}
