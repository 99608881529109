import {
  CalculationDetailResponse,
  CalculationStatus,
  CalculationWithOverviewResponse,
  CalculationWithRelatedEntitiesResponse,
  DataRetrievalStatus,
} from '@ydistri/api-sdk';
import { IProjectSignal } from '../../signalr/signalrInterfaces';
import { IconButton_IconSizes, IconButtonSize, InfiniteScrollParams } from '@ydistri/ds';
import { AiFillEye, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import React from 'react';

export enum PrivateFilterType {
  ALL = 'all',
  MINE = 'mine',
  NONE = 'none',
}

export const getIconBasedOnPrivateFilterType = (
  pft: PrivateFilterType,
  size: IconButtonSize = 'xSmall',
): React.ReactElement => {
  switch (pft) {
    case PrivateFilterType.ALL:
      return <AiFillEye size={IconButton_IconSizes[size]} />;
    case PrivateFilterType.MINE:
      return <AiOutlineEye size={IconButton_IconSizes[size]} />;
    case PrivateFilterType.NONE:
      return <AiOutlineEyeInvisible size={IconButton_IconSizes[size]} />;
  }
};

export interface CalculationsTableFilters {
  showAllPrivateCalculations: PrivateFilterType;
  showCurrentUserCalculationsOnly: boolean;
  showProductionOnly: boolean;
}

export interface CalculationTableParams extends InfiniteScrollParams {
  includePrivateCalculations?: PrivateFilterType;
  productionCalculationsOnly?: boolean;
  calculationOwner?: string;
  showOwnersCalculationsOnly?: boolean;
}

export enum CalculationActionType {
  DELETE = 'delete',
  PRIVATE = 'private',
  UNSET_PRIVATE = 'unset_private',
}

export interface SignalCalculationStatusChanged extends IProjectSignal {
  calculationId: number;
  status: CalculationStatus;
}

export interface RedistributionValues {
  totalValue: number;
  deadStockValue: number;
  slowMoverValue: number;
  fastMoverValue: number;
  isProduction: boolean;
}

export interface SignalCalculationFileStatusChanged extends IProjectSignal {
  calculationId: number;
  dataRetrievalStatus: DataRetrievalStatus;
  fileId: number;
}

export type CalculationDataType<T = object> = (
  | CalculationWithRelatedEntitiesResponse
  | CalculationWithOverviewResponse
) &
  T;

export type CalculationInfo = Pick<CalculationDetailResponse, 'id' | 'title'>;
